import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "Powerbranding For New Retail",
  date: "2018-05-10",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20180510-0.jpg",
  path: "2018-05-10-Powerbranding",
  teaserText: "As technology allows us to outsource most of the functional elements in life, we search for bigger emotional plays that give us meaning."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Powerbranding For New Retail" description="As technology allows us to outsource most of the functional elements in life, we search for bigger emotional plays that give us meaning." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="h1" components={components}>{`“What do you bring to this party?”`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is the very direct and consequential challenge consumers are posing to brands today. Empowered with an increasingly sophisticated eye and shiny new filtering tools, these new no-nonsense consumers are precisely editing what they buy, and blocking out most products, brands and experiences they don’t relate to. Many brands and stores have fallen by the wayside due to this powerful moment of reckoning. And who can blame these consumers for locking out noise that is irrelevant as they react to a flurry of sameness and too much stuff in the world?`}</MDXTag>
      <MDXTag name="p" components={components}>{`The traditional model of designing a product and pushing it out through distribution and marketing channels simply does not work. First, because many of those distribution models and players, such as traditional department stores, are struggling with navigating the digital marketplace. Second, consumers are going straight to source materials they trust. This return to the core is part of a bigger reaction to the larger sociocultural and political shifts taking place that are leaving customers feeling unsettled.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Individuals do not want to get lost in the big shuffle and shakeout that is taking place today. They want to find their place and footing, and they are doing this through a search for more singular purpose. Those brand players that can deliver this, both rationally and emotionally, will succeed. The new model of Powerbrand is identified by the purposeful match between consumers’ lives, what brands stand for and what they bring to the table.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Rules For Powerbranding`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1. Define your purpose.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As technology allows us to outsource most of the functional elements in life, we search for bigger emotional plays that give us meaning. People want to be inspired by icons, heroes and everyday people that make a difference. The stakes are as high, if not higher for brands. Consumers welcome Warby Parker taking on Luxottica, Netflix taking on cable and Patagonia taking on the government’s anti-environmental policies.`}</MDXTag>
      <ImageLandscape imageSrc="20180510-1.jpg" caption="Patagonia" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Think:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`What are all of the ways you can shakeup your traditional thinking? Welcome, pamper, protect and elevate your consumer. Embrace contradictions to test new concepts. Explore the power of nimble and thinking small at a time when consumers repel big and impersonal. Include smaller formats, conversation and personalized shopping. Embrace agility and test things quickly on a smaller scale. Be a bit scrappy to expand your knowledge base. All of this will entice and engage a learning-focused, experience-seeking consumer.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3. Get in the relationship business.`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Consumers want to deal with less stuff and fewer brands. There is an opportunity to deliver on shared purpose and experience and earn more of the savvy consumers’ business; approach branding as an elevated form of relationship marketing. Take inspiration from the timeless quality of how people connect and grow closer together. Foster a better listening culture; look into smarter ways to map out consumer needs and aspiration. Explore the fine balance between high tech and high touch in satisfying consumers’ functional and emotional needs.`}</MDXTag>
      <ImageLandscape imageSrc="20180510-2.jpg" caption="Everlane" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Think:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`What is your challenger strategy? How are you purposefully unique and how can this uniqueness inform everything you do, beginning with product? Tap into the power of purpose to inform and rock your overall business and perhaps, most importantly, your work and employee culture. Retool your own business with these powerful constructs to unlock true powerbranding.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2. Storydoing is the new storytelling.`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Impatient and skeptical, consumers want transparency from every perspective. They want to peel back the layers and discover what you really deliver. Live and breathe your story: storydoing. Business models like Everlane bring fair and innovative ways in which they deliver great fashion to the forefront while tackling lots of the industry’s legacy baggage. In this world, product, purpose and actions are intertwined, with the latter engaging the consumer seeking true innovation. In today’s world, how a brand goes to market, how it treats its people and how it embraces change and innovation are more powerful than any shiny advertising campaign. Storydoing is also inherently driven by collaborations with consumers volunteering to be part of the mix.`}</MDXTag>
      <ImageLandscape imageSrc="20180510-3.jpg" caption="Glossier" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Think:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`How can I elevate loyalty marketing? What are the ways to bring your best customers into your design and marketing strategies? Explore natural relationship-based upselling opportunities such as subscription plays or tapping into adjacent categories of business that are highly relevant for lifestyle and fashion brands.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4. Own the creative era.`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`The modern consumer wants a product and brand experience that evokes an impeccable, yet fluid and open way of seeing the world. Spoiled and trained by better design through Apple and Tesla, applied trend crafting through Pinterest boards and more immersive forms of storytelling through Netflix, today’s consumers curate aesthetically for themselves. In a cluttered world, aesthetic markers have never been as important, acting as quick cuts to signal tribal affiliations. Think of Supreme’s take on urban disruption and urgency or Kinfolk magazine’s advocacy for the simple and rustic – albeit highly designed – life.`}</MDXTag>
      <ImageLandscape imageSrc="20180510-4.jpg" caption="Kinfolk" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Think:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`What is your brand’s design aesthetic? Go beyond the simple level and explore your brand’s take on storytelling, especially in the era of more collaborative story creation. Explore nascent elements such as gamefication and augmented reality. Think of the power of artistic collaborations. Get out of your own comfort zone by tapping into other lifestyles and artistic impressions that can give your brand a new life. Get into your power zone.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Building a powerbrand begins with your own sense of vision and purpose. Embrace creative tensions and contradictions and test new ways to challenge old notions and challenges. Embrace the swiftness of change but respect the slowness required of perspective and analysis. Don’t be afraid to fail, as no new insight is gained without pushing yourself beyond your limits. In an industry that seems paranoid, reactionary and timid, have the courage to chart new territory and you will find your most natural, best customer waiting for you.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    